import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Iqac.css";
import StarRateIcon from "@mui/icons-material/StarRate";
import initiatives from "../../assets/images/iqac-initives.png";
import axios from "axios";
import { resources } from "../Resourses/Resources";
import R2324 from "../../Pdf/Iqac/IQAC_MINUTES_OF_MEETING_2023-24.pdf";
import R2223 from "../../Pdf/Iqac/IQAC  2022-23.pdf";
import R2122 from "../../Pdf/Iqac/IQAC 2021-22.pdf";
import R2021 from "../../Pdf/Iqac/IQAC 2020-21.pdf";
import R1920 from "../../Pdf/Iqac/IQAC 2019-20.pdf";
import R1819 from "../../Pdf/Iqac/IQAC 2018-19.pdf";
import R1718 from "../../Pdf/Iqac/IQAC 2017-18.pdf";

const Iqac = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const overview = [
    {
      title: "To sustain the quality of all the academic process and outcomes with continuous improvement, Internal Quality Assurance Cell (IQAC) established at the college level shall regularly monitor all the academic operations as per the academic regulations and SOPs set for each of the various processes. Towards realization of the goals of quality enhancement & sustenance, IQAC with external members nominated from universities of repute shall conduct academic audit annually while internal academic audit will be done every semester.",
    }
  ]
  const objectives = [
    {
      title:"To develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the institution."

    },
    {
      title:"The IQAC will provide greater clarity and focus in institutional functioning towards quality enhancement and facilitate internalization of the quality culture."
    },
    {
      title:"To promote measures for institutional functioning towards quality enhancement through internalization of quality culture and institutionalization of best practices."
    }
  ]

  const statogies = [
    {
      title:"Ensuring timely, efficient and progressive performance of academic, administrative and financial tasks."
    },
    {
      title:"The relevance and quality of academic and research programmes."
    },
    {
      title:"Equitable access to and affordability of academic programmes for various sections of society."

    },
    {
      title:"Optimization and integration of modern methods of teaching and learning."
    },
    {
      title:"Ensuring the adequacy, maintenance and proper allocation of support structure and services."

    },
    {
      title:"Sharing of research findings and networking with other institutions in India and abroad."
    }
  ];
  const functions = [
    {
      title:"Dissemination of information on various quality parameters of higher education. Acting as a nodal agency of the Institution for coordinating quality-related activities."
    },
    {
      title:"Development of Quality Culture in the institution."
    },
    {
      title:"Development and application of quality benchmarks/parameters for the various academic and administrative activities of the institute."

    },
    {
      title:"Facilitating the creation of a learner-centric environment conductive for quality education and faculty maturation to adopt the required knowledge and technology for participatory teaching and learning process."
      
    }, 
    {
      title:"Arrangement for feedback responses from students, parents and other stakeholders on quality-related institutional processes."
    },
    {
      title:"Organization of inter and intra institutional workshops, seminars on quality related themes and promotion of quality circles."
    },
    {
      title:"Documentation of various programmes/activities of the institute leading to quality improvement."
    }
  ];

  const Benefits = [
    {
      title:"Ensure heightened level of clarity and focus in institutional functioning towards quality enhancement."
    },
    {
      title:"Act as a dynamic system for quality changes in HEIs."
    },
    {
      title:"Ensure enhancement and coordination among various activities of the institution and institutionalize all good practices."
    },
    {
      title:"Build an organized methodology of documentation and internal communication."
    },
    {
      title:"Provide a sound basis for decision-making to improve institutional functioning."
    }
  ]

  const members = [
    {
      memberName: "Dr.S.Gopi Krishna",
      category:"Head of the Institute",
      position: "Chairman",
    },
    {
      memberName: "Dr. Sk. J. Shareef, Professor & HoD, MBA",
      category:"Coordinator of IQAC",
      position: "Secretary",
    },
    {
      memberName: "Dr. S. V. Naresh, Assoc. Professor, ECE",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "M. Ashok Naga Sai, Asst. Professor, AI",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "Dr. Sk. Md. Rafi, Professor & HoD, AI & IT",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "S.V. Kishore Kumar, Asst. Professor, CSE",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "M. Saraswathi, Assoc. Professor, MBA",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "M. Jaya Rao, Asst. Professor, ASH",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "M. V. Ramana, Asst. Professor, CSE",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "K. Tirupathaiah, Asst. Professor, ECE",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "E. Adi Narayana, Assoc. Professor, CSE",
      category:"Faculty Representatives",
      position: "Member",
    },
    {
      memberName: "M. Kishore Kumar, Vice-President, SMCE",
      category:"Management Representative",
      position: "Member",
    },
    {
      memberName: "Dr. S. Gopi Krishna, Vice-Principal, Professor & HoD CSE.",
      category:"Senior Administrative Faculty",
      position: "Member",
    },
    {
      memberName: "Dr. M. NageswaraRao, Assoc. Professor, ECE",
      category:"Senior Administrative Faculty",
      position: "Member",
    },
    {
      memberName: "Dr. V. Madhuri, Assoc. Professor & HoD ASH.",
      category:"Female Faculty Representative",
      position: "Member",
    },
    {
      memberName: "Dr. Y. Saritha, Assoc. Professor, ASH",
      category:"Female Faculty Representative",
      position: "Member",
    },
    {
      memberName: "M. NagaLakshmi, Assoc. Professor, ASH",
      category:"Female Faculty Representative",
      position: "Member",
    },
    {
      memberName: "MM. ParameswaraRao, Secretary, Alumni Association",
      category:"Nominee from Alumni",
      position: "Member",
    },
    {
      memberName: "A. Revanth, Savantis Solutions, Hyderabad",
      category:"Nominee from Employers",
      position: "Member",
    },
    {
      memberName: "G. Shasidhar, General Manager, Mittapalli Spinners Ltd.",
      category:"Nominee from Industrialists",
      position: "Member",
    },
    {
      memberName: "B. Sai Akash Reddy (19U91A0517)",
      category:"Student Representative",
      position: "Member",
    },
    {
      memberName: "MJ. Lahari (19U91A0565)",
      category:"Student Representative",
      position: "Member",
    },

  ];

  const aqar = [


    {
      year: "2023-24",
      link: R2324,
    },
  
    {
      year: "2022-23",
      link: R2223,
    },
    {
      year: "2021-22",
      link: R2122,
    },
    {
      year: "2020-21",
      link: R2021,
    },
    {
      year: "2019-20",
      link: R1920,
    },
    {
      year: "2018-19",
      link: R1819,
    },
    {
      year: "2017-18",
      link: R1718,
    },
  ];
  return (
    <div>
      <h1 className="iqac_header">IQAC</h1>
      <Box sx={{ width: "100%", typography: "body1", textAlign: "center" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              centered
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="IQAC Home"
                value="1"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                  
                }}
                label="IQAC Members"
                value="2"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="IQAC A.Y Reports"
                value="3"
              />
              
            </TabList>
          </Box>
          <TabPanel value="1">
            <h3>INTERNAL QUALITY ASSURANCE CELL (IQAC)</h3>

            <div className="iqac_cuntant_container">
              <div>
                <p>
                  <span className="iqac_sidehead">Overview :</span>
                  <br />
                </p>
                {overview.map((item, index) =><div style={{ display: "flex" }}>
                    {/* <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp; */}
                    <p>
                      {" "}
                      {item.title}
                    </p>
                  </div>)}
                <p>
                  <span className="iqac_sidehead">Objectives :</span>
                  <br />
                </p>
                {objectives.map((item, index) =><div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      {item.title}
                    </p>
                  </div>)}
                  
                <p>
                  <span className="iqac_sidehead">Strategies :</span>
                  <br />
                </p>
                {statogies.map((item, index) =><div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      {item.title}
                    </p>
                  </div>)}
                  <p>
                  <span className="iqac_sidehead">Functions :</span>
                  <br />
                </p>
                {functions.map((item, index) =><div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      {item.title}
                    </p>
                  </div>)}
                  <p>
                  <span className="iqac_sidehead">Benefits :</span>
                  <br />
                </p>
                {Benefits.map((item, index) =><div style={{ display: "flex" }}>
                    <StarRateIcon sx={{ marginRight: "1px" }} /> &nbsp;&nbsp;
                    <p>
                      {" "}
                      {item.title}
                    </p>
                  </div>)}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <h3>INTERNAL QUALITY ASSURANCE CELL MEMBERS</h3>
            <div className="iqac_table_container">
              <table className="gb_table">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>MEMBER NAME</th>
                    <th>CATEGORY</th>
                    <th>POSITION</th>
                  </tr>
                </thead>
                {members.map((each, index) => (
                  <tbody className="iqac_table_body">
                    <tr>
                      <td>{index + 1}</td>
                      <td>{each.memberName}</td>
                      <td>{each.category}</td>
                      <td>{each.position}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </TabPanel>

          <TabPanel value="3">
            <h3>IQAC Minutes & Action Taken Report</h3>
            <div className="aqar_button_container">
              {aqar.map((each) => (
                <a href={each.link} target="_blank">
                <button>
                  {each.year}
                </button>
                </a>
              ))}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Iqac;
