import React from 'react'

const Introduction = () => {
  return (
    <div>
      <p className='gr_header'>
        INTRODUCTION
      </p>
      <p style={{ textAlign: "justify", fontSize: "14px", }}>
        In order to provide opportunities for redressal of certain grievances of students have already enrolled in any institution, as well as for those seeking admission to such institutions, AICTE has notified All India Council for Technical Education (Redressal of Grievance of Students) Regulation, 2019 vide F.No.1-101/PGRC/AICTE/Regulation/2019 dated 07.11.2019 for establishment of Grievance Redressal Mechanism for all AICTE approved Technical Institutions. (Ref – AICTE Approval Process Handbook 2020-21) Sri Mittapalli College of Engineering, follows these regulation and guidelines to establish the Student Grievance Redressal Committee.
      </p>
      <label style={{ fontWeight: "bold", fontSize: "17px" }}>Who Can Complain-</label>
      <p style={{ textAlign: "justify", fontSize: "14px" }}>
        A student in person or a group of students, if feel unfair practices in any of the institution’s operations that can cause threat to his/her/their opportunities in education, carrier enhancement, cultural enhancement, and Personality Development can submit the complaint. If a student feels humiliated, assaulted by teacher/coordinator/administrator/other students he can go for the grievances. A student, while he is enrolled in the Institute for any of its courses can go for Grievance – Redressal by dropping the written complaint online.
      </p>
      <p style={{ textAlign: "justify", fontSize: "14px" }}>
        The student grievances related to ragging cases and internal complaint committee could be submitted to the committee by filling the form of online complaint box. Student aggrieved shall mention about the same in his/her complaint message.
      </p>
      <p style={{ textAlign: "justify", fontSize: "14px" }}>The URL of the online Grievance Redressal Portal - <a target='_blank' href=" https://smce.ac.in/"> https://smce.ac.in</a></p>
    </div>
  )
}

export default Introduction
