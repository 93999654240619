import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "../Naac/Iqac.css";

// Affiliation files
import AFILES2021 from "../../Pdf/AICTE/AffliationFiles/Affiliation20.pdf";
import AFILES2122 from "../../Pdf/AICTE/AffliationFiles/Affiliation21.pdf";
import AFILES2223 from "../../Pdf/AICTE/AffliationFiles/Affiliation22.pdf";
import AFILES2324 from "../../Pdf/AICTE/AffliationFiles/Affiliation23.pdf";
import AFILES2425 from "../../Pdf/AICTE/AffliationFiles/Affiliation24.pdf";

// Affiliation Letters
import AICTEAFF2021 from "../../Pdf/AICTE/AffliationLetters/AICTE20.pdf";
import AICTEAFF2122 from "../../Pdf/AICTE/AffliationLetters/AICTE21.pdf";
import AICTEAFF2223 from "../../Pdf/AICTE/AffliationLetters/AICTE22.pdf";
import AICTEAFF2324 from "../../Pdf/AICTE/AffliationLetters/AICTE23.pdf";
import AICTEAFF2425 from "../../Pdf/AICTE/AffliationLetters/AICTE24.pdf";

const AICTEStatutory = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const members = [
    { acadmicyear: "2024-2025", pdfpath: AFILES2425 },
    { acadmicyear: "2023-2024", pdfpath: AFILES2324 },
    { acadmicyear: "2022-2023", pdfpath: AFILES2223 },
    { acadmicyear: "2021-2022", pdfpath: AFILES2122 },
    { acadmicyear: "2020-2021", pdfpath: AFILES2021 },
  ];

  const aqar = [
    { year: "2024-25", link: AICTEAFF2425 },
    { year: "2023-24", link: AICTEAFF2324 },
    { year: "2022-23", link: AICTEAFF2223 },
    { year: "2021-22", link: AICTEAFF2122 },
    { year: "2020-21", link: AICTEAFF2021 },
  ];

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1", textAlign: "center" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList centered onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={{ fontSize: "15px", fontWeight: "bold", color: "rgb(37, 31, 31)" }}
                label="Affiliation files"
                value="1"
              />
              <Tab
                sx={{ fontSize: "15px", fontWeight: "bold", color: "rgb(37, 31, 31)" }}
                label="Affiliation letters"
                value="2"
              />
            </TabList>
          </Box>

          <TabPanel value="1">
            <h2 style={{fontFamily:"Lucid",fontWeight:"bold"}}> AICTE Affiliation files</h2>
            <div className="aqar_button_container">
              {members.map((each, index) => (
                <a href={each.pdfpath} target="_blank" rel="noopener noreferrer" key={index}>
                  <button>{each.acadmicyear}</button>
                </a>
              ))}
            </div>
          </TabPanel>

          <TabPanel value="2">
            <h2 style={{fontFamily:"Lucid",fontWeight:"bold"}}>AICTE Affiliation letters</h2>
            <div className="aqar_button_container">
              {aqar.map((each, index) => (
                <a href={each.link} target="_blank" rel="noopener noreferrer" key={index}>
                  <button>{each.year}</button>
                </a>
              ))}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default AICTEStatutory;
